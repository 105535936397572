import React, { useEffect, useState, useContext } from 'react';
import Axios from 'axios';
import { Context } from "../utils/store";
import { room } from "../utils/interfaces";
import { Empty, Card, Row, Col, Button, Typography, Space, message } from 'antd';
import { apiUrl } from "../utils/config";
import { handleTokenExpired } from "../utils/utils";
import { format } from 'date-fns';

const { Title, Paragraph, Text } = Typography;

interface KinoReservationProps {
  room: room;
}

interface Event {
  EventID: number;
  Name: string;
  NumberOfSeats: number;
  Description: string;
  AvailableSeats: number;
  Start: string;
  End: string;
  Image: string;
}

const KinoReservation: React.FC<KinoReservationProps> = ({ room }) => {
  const { state, dispatch } = useContext(Context);
  const [events, setEvents] = useState<Event[] | null>(null);
  const [isMobile, setIsMobile] = useState<boolean>(false);

  const fetchEvents = () => {
    Axios.get<Event[]>(`${apiUrl}/events/`, {
      headers: {
        Authorization: `JWT ${state.token}`,
      },
    })
      .then((res) => {
        setEvents(res.data);
      })
      .catch((e) => {
        let isExpired = handleTokenExpired(e.response?.status, () => {
          dispatch({ type: "Logout", payload: null });
        });
        if (!isExpired) {
          console.log(e.response.data);
        }
      });
  };

  useEffect(() => {
    fetchEvents();
  }, [state.token]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleReserve = (eventID: number) => {
    Axios.post(`${apiUrl}/subscription/subscribe/`, null, {
      params: {
        event_id: eventID,
        start: 1727928000,
        end: 1727985600,
      },
      headers: {
        Authorization: `JWT ${state.token}`,
      },
    })
      .then((res) => {
        console.log("Reservation successful", res.data);
        message.success("User successfully subscribed");
        fetchEvents();
      })
      .catch((e) => {
        let isExpired = handleTokenExpired(e.response?.status, () => {
          dispatch({ type: "Logout", payload: null });
        });
        if (!isExpired) {
          console.log(e.response.data);
          message.error("User already has an active subscription");
        }
      });
  };

  const handleCancel = (eventID: number) => {
    Axios.delete(`${apiUrl}/subscription/subscribe/`, {
      params: {
        event_id: eventID,
        start: 1727928000,
        end: 1727985600,
      },
      headers: {
        Authorization: `JWT ${state.token}`,
      },
    })
      .then((res) => {
        console.log("Reservation cancelled", res.data);
        message.success("Unsubscribed successfully");
        fetchEvents();
      })
      .catch((e) => {
        let isExpired = handleTokenExpired(e.response?.status, () => {
          dispatch({ type: "Logout", payload: null });
        });
        if (!isExpired) {
          console.log(e.response.data);
          message.error("No active subscription found or already unsubscribed");
        }
      });
  };

  if (!events || events.length === 0) {
    return <Empty description="No events available" />;
  }

  return (
    <div style={styles.container}>
      <Space direction="vertical" size="large">
        <Title level={2}>Events Reservation</Title>
        <Row gutter={[16, 16]}>
          {events.map((event) => (
            <Col key={event.EventID} xs={24} sm={24} md={24} lg={24} xl={24} style={styles.column}>
              {isMobile ? (
                <Card hoverable style={styles.mobileCard}>
                  <div style={styles.mobileImageWrapper}>
                    <img
                      alt="Movie poster"
                      src={event.Image || "https://via.placeholder.com/150x200"}
                      style={styles.mobileImage}
                    />
                  </div>
                  <div style={styles.mobileTextContent}>
                    <Title level={4} style={styles.mobileCardTitle}>{event.Name}</Title>
                    <Paragraph>{event.Description}</Paragraph>
                    <Paragraph>
                      <Text strong>Total Seats:</Text> {event.NumberOfSeats} |
                      <Text strong> Available Seats:</Text> {event.AvailableSeats}
                    </Paragraph>
                    <Paragraph>
                      <Text strong>Start:</Text> {format(new Date(event.Start), 'PPpp')}
                    </Paragraph>
                    <Paragraph>
                      <Text strong>End:</Text> {format(new Date(event.End), 'PPpp')}
                    </Paragraph>
                    <div style={styles.buttonContainer}>
                      <Button
                        type="primary"
                        style={styles.reserveButton}
                        onClick={() => handleReserve(event.EventID)}
                      >
                        Reserve
                      </Button>
                      <Button
                        style={styles.cancelButton}
                        onClick={() => handleCancel(event.EventID)}
                      >
                        Cancel Reservation
                      </Button>
                    </div>
                  </div>
                </Card>
              ) : (
                // Версия для монитора
                <Card hoverable style={styles.card}>
                  <div style={styles.cardContent}>
                    <div style={styles.imageWrapper}>
                      <img
                        alt="Movie poster"
                        src={event.Image || "https://via.placeholder.com/150x200"}
                        style={styles.image}
                      />
                    </div>
                    <div style={styles.textContent}>
                      <div style={styles.titleRow}>
                        <Title level={4} style={styles.cardTitle}>{event.Name}</Title>
                        <Text style={styles.seatInfo}>
                          <Text strong>Total Seats:</Text> {event.NumberOfSeats} |
                          <Text strong> Available Seats:</Text> {event.AvailableSeats}
                        </Text>
                      </div>
                      <Paragraph>
                        <Text strong>Start:</Text> {format(new Date(event.Start), 'PPpp')}
                      </Paragraph>
                      <Paragraph>
                        <Text strong>End:</Text> {format(new Date(event.End), 'PPpp')}
                      </Paragraph>
                      <Paragraph>{event.Description}</Paragraph>
                      <div style={styles.buttonContainer}>
                        <Button
                          type="primary"
                          style={styles.reserveButton}
                          onClick={() => handleReserve(event.EventID)}
                        >
                          Reserve
                        </Button>
                        <Button
                          style={styles.cancelButton}
                          onClick={() => handleCancel(event.EventID)}
                        >
                          Cancel Reservation
                        </Button>
                      </div>
                    </div>
                  </div>
                </Card>
              )}
            </Col>
          ))}
        </Row>
      </Space>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
  },
  column: {
    display: 'flex',
    justifyContent: 'center' as 'center',
  },
  card: {
    width: '100%', // Полная ширина страницы
    marginBottom: '20px',
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'row' as 'row', // Картинка слева, текст справа
    justifyContent: 'space-between' as 'space-between',
    alignItems: 'center' as 'center',
    width: '100%',
  },
  imageWrapper: {
    flexShrink: 0,
    marginRight: '20px',
  },
  image: {
    width: '250px', // Увеличен размер изображения
    height: '350px',
    objectFit: 'cover' as 'cover',
  },
  textContent: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    flexGrow: 1,
    justifyContent: 'space-between' as 'space-between',
    alignItems: 'flex-start' as 'flex-start',
    width: '100%',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between' as 'space-between',
    width: '100%',
    flexWrap: 'wrap' as 'wrap',
  },
  cardTitle: {
    fontSize: '24px',
    marginBottom: '0',
  },
  seatInfo: {
    fontSize: '16px',
    marginTop: '5px',
  },
  buttonContainer: {
    marginTop: '10px',
    display: 'flex',
    justifyContent: 'space-between' as 'space-between',
    width: '100%',
  },
  reserveButton: {
    flex: 1,
    marginRight: '10px',
  },
  cancelButton: {
    flex: 1,
  },
  mobileCard: {
    width: '100%',
    marginBottom: '20px',
    textAlign: 'center' as 'center',
  },
  mobileImageWrapper: {
    marginBottom: '20px',
  },
  mobileImage: {
    width: '150px',
    height: '200px',
    objectFit: 'cover' as 'cover',
  },
  mobileTextContent: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center' as 'center',
  },
  mobileCardTitle: {
    fontSize: '20px',
  },
};

export default KinoReservation;

